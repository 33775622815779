import { uniqueId } from 'lodash'
import { PlatformAppPerformanceEvent, ServerPerformanceEvent } from '@wix/thunderbolt-types'

export const SSRPerformanceStore = (initialData: Array<ServerPerformanceEvent> = []) => {
	const eventData: Array<ServerPerformanceEvent> = initialData
	const platformAppEvents: Map<string, PlatformAppPerformanceEvent> = new Map()
	const resourceFetchEvents: Map<string, PlatformAppPerformanceEvent> = new Map()

	const addSSRPerformanceEvent = (name: string) => {
		eventData.push({ name: `${name} (server)`, startTime: Date.now() })
	}
	const addSSRPerformanceEvents = (events: Array<ServerPerformanceEvent>) => {
		eventData.push(...events)
	}
	const getAllSSRPerformanceEvents = () => eventData

	function addPlatformAppEvent(eventId: string, event: PlatformAppPerformanceEvent) {
		platformAppEvents.set(eventId, { startTime: Date.now(), ...event })
	}

	function finishPlatformAppEvent(eventId: string, error?: string) {
		const event = platformAppEvents.get(eventId)
		if (event) {
			event.endTime = Date.now()
			if (error) {
				event.error = error
			}
		}
	}

	function addResourceFetchEvent(event: PlatformAppPerformanceEvent) {
		const eventId = uniqueId('fetchResource')
		resourceFetchEvents.set(eventId, { task: 'fetchResource', startTime: Date.now(), ...event })

		return function finishResourceFetchEvent(error?: string) {
			const ev = resourceFetchEvents.get(eventId)
			if (ev) {
				ev.endTime = Date.now()
				if (error) {
					ev.error = error
				}
			}
		}
	}

	function getAllPlatformAppEvents() {
		return Array.from(platformAppEvents.values())
	}

	function getAllResourceFetchEvents() {
		return Array.from(resourceFetchEvents.values())
	}

	return {
		addSSRPerformanceEvent,
		addResourceFetchEvent,
		getAllSSRPerformanceEvents,
		addSSRPerformanceEvents,
		addPlatformAppEvent,
		finishPlatformAppEvent,
		getAllPlatformAppEvents,
		getAllResourceFetchEvents,
	}
}
